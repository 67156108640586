//React Components
import axios from "axios";

// Audit Vault Utilities
import { encryptKey } from './common-encrypt-util';

// Audit Vault Constants
const WEB_API_URL = process.env.REACT_APP_WEB_API_URL;


export async function getTenantById(id) {
    var rsaKey = await encryptKey(`{Id: "${id}" }`);
    if (rsaKey) {
        var result = await axios.get(`${WEB_API_URL}Tenant/GetTenant`, {
            params: {
                key: `${rsaKey}`
            }
        });
        if (result.data != null) {
            return result.data;
        }
    }
}

export async function deleteTenantById(companyId, tenantId, user) {

    var usrEmail = user.userEmail;
    if (!usrEmail)
        usrEmail = "N/A";

    var payload = `{
        CompanyId: ${companyId},
        Id: ${tenantId},
        UserMicrosoftId: "${await encryptKey(user.microsoftGraphId)}",
        UserEmail: "${await encryptKey(usrEmail)}"}`;

    if (payload) {
        var result = await axios.delete(`${WEB_API_URL}Tenant/DeleteTenant`, {
            params: {
                key: `${payload}`
            }
        });
        return result;
    }
}

export async function testIfPowershellEnabled(id) {
    var rsaKey = await encryptKey(`{Id: "${id}" }`);
    if (rsaKey) {
        var result = await axios.get(`${WEB_API_URL}Tenant/TestIfPowershellEnabledOnTenant`, {
            params: {
                key: `${rsaKey}`
            }
        });
        if (result.data != null) {
            return result.data;
        }
    }
}

export async function getHistoricalImportAuditStats(id) {
    var rsaKey = await encryptKey(`{Id: "${id}" }`);
    if (rsaKey) {
        var result = await axios.get(`${WEB_API_URL}Tenant/GetHistoricalImportAuditStats`, {
            params: {
                key: `${rsaKey}`
            }
        });
        if (result.data != null) {
            return result.data;
        }
    }
}

export async function getFirstAuditImportStats(id) {
    var rsaKey = await encryptKey(`{Id: "${id}" }`);
    if (rsaKey) {
        var result = await axios.get(`${WEB_API_URL}Tenant/GetFirstAuditImportStats`, {
            params: {
                key: `${rsaKey}`
            }
        });
        if (result.data != null) {
            return result.data;
        }
    }
}


export async function getFirstAuditImportJobs(id) {
    var rsaKey = await encryptKey(`{Id: "${id}" }`);
    if (rsaKey) {
        var result = await axios.get(`${WEB_API_URL}Tenant/GetFirstAuditImportJobs`, {
            params: {
                key: `${rsaKey}`
            }
        });
        if (result.data != null) {
            return result.data;
        }
    }
}


export async function getAuditHistoryByTenantIdAndJobId(tenantId, jobId) {
    var rsaKey = await encryptKey(`{TenantId: "${tenantId}", JobId: "${jobId}" }`);
    if (rsaKey) {
        var result = await axios.get(`${WEB_API_URL}Tenant/GetAuditHistoryByTenantIdAndJobId`, {
            params: {
                key: `${rsaKey}`
            }
        });
        if (result.data != null) {
            return result.data;
        }
    }
}


export async function getHistoricalJobQueuesByTenantIdFromTenantDb(id) {
    var rsaKey = await encryptKey(`{Id: "${id}" }`);
    if (rsaKey) {
        var result = await axios.get(`${WEB_API_URL}Tenant/GetHistoricalJobQueuesByTenantIdFromTenantDb`, {
            params: {
                key: `${rsaKey}`
            }
        });
        if (result.data != null) {
            return result.data;
        }
    }
}

export async function getHistoricalAuditHistoryByTenantIdAndJobId(tenantId, jobId) {
    var rsaKey = await encryptKey(`{TenantId: "${tenantId}", JobId: "${jobId}" }`);
    if (rsaKey) {
        var result = await axios.get(`${WEB_API_URL}Tenant/GetHistoricalAuditHistoryByTenantIdAndJobId`, {
            params: {
                key: `${rsaKey}`
            }
        });
        if (result.data != null) {
            return result.data;
        }
    }
}

export async function getDiagnosticLogsForLastXNumOfDaysAndMinLevel(tenantId, userId, numOfDays, minDiagnosticLevel) {
    var rsaKey = `{TenantId: "${await encryptKey(tenantId.toString())}", UserId: "${await encryptKey(userId)}", NumOfDays: "${numOfDays.toString()}", MinDiagnosticLevel: "${minDiagnosticLevel.toString()}" }`;
    if (rsaKey) {

        var result = await axios.get(`${WEB_API_URL}AdminUtilities/GetDiagnosticLogsForLastXNumOfDaysAndMinLevel`, {
            params: {
                key: `${rsaKey}`
            }
        });
        if (result) {
            return result.data;
        }
    }
}


export async function updateTenantSetPowerShellAdminConsent(azureTenantId, userNm, userMicrosoftId) {
    var rsaKey = `{AzureTenantId: "${await encryptKey(azureTenantId)}", UserNm: "${await encryptKey(userNm)}", UserMicrosoftId: "${await encryptKey(userMicrosoftId)}" }`;
    if (rsaKey) {
        var result = await axios.put(`${WEB_API_URL}Tenant/UpdateTenantSetPowerShellAdminConsent`, null, {
            params: {
                key: rsaKey,
            }
        })
        if (result.data != null) {
            return result.data;
        }
    }
}

export async function createHistoricalAuditHistoryJobs(id) {
    var rsaKey = await encryptKey(`{Id: "${id}" }`);
    if (rsaKey) {
        var result = await axios.post(`${WEB_API_URL}Tenant/CreateHistoricalAuditHistoryJobs`, null, {
            params: {
                key: `${rsaKey}`
            }
        });
        if (result.data != null) {
            return result.data;
        }
    }
}

export async function createFirstAuditImportJobs(id, user) {

    console.log(user);
    var usrEmail = "N/A";
    if (user.userEmail)
        usrEmail = user.userEmail;

    var payload = `{Id: "${id.toString()}", 
                    UserMicrosoftGraphId: "${await encryptKey(user.microsoftGraphId)}",
                    UserEmail: "${await encryptKey(usrEmail)}" }`;
    if (payload) {
        var result = await axios.post(`${WEB_API_URL}Tenant/CreateFirstAuditImportJobs`, null, {
            params: {
                key: `${payload}`
            }
        });
        if (result.data != null) {
            return result.data;
        }
    }
}

export const getDiagnosticLogLevel = (id) => {

    switch (id) {

        case 1:
            return "None";

        case 2:
            return "Critical";

        case 3:
            return "Warning";

        case 4:
            return "Information";

        default:
            return "Verbose";
    }
};

